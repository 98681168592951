<template>
<v-chart class="chart" :option="option" />
</template>

<script>
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  BarChart
} from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from "echarts/components";
import VChart, {
  THEME_KEY
} from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent
]);
import 'echarts/lib/component/visualMap'
export default {
  name: "Barchart",
  components: {
    VChart
  },
  props: ['data', 'palette', 'title'],
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    option: function () {
      return {
        title: {
          text: this.title
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          width: 10
        },
        yAxis: {
          type: 'category',
          data: [''],
          width: 10
        },
        series: [{
            name: this.data[0].x,
            type: 'bar',
            data: [this.data[0].y],
          },
          {
            name: this.data[1].x,
            type: 'bar',
            data: [this.data[1].y]
          },
        ],
        color: this.palette,
      }
    },
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
